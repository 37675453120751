.table {
    width: 100%;
    margin-top: 20px;
    color: #6a5d5d;
}

.table__body {
    display: block;
    height: 400px;
    overflow-y: scroll;
}

.table tr {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.table tr:nth-of-type(odd) {
    background-color: #f3f2f8;
}

.table td {
    padding: 0.5rem;
}

.app__right__cases {
    margin-top: 1rem;
}

.searchBox {
    margin-top: 0.5rem;
    height: 48px;
    padding: 0.25rem 0.5rem;
    width: 100%;
    font-size: 18px;
}
