* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f6fa;
}

.app__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app__stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__left {
  flex: 0.9;
}

.app {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.app__header > h1{
  color: #fc3c3c;
  font-size: 2rem;
}

.app__dropdown{
  background-color: white;
}

.infoBox {
  cursor: pointer;
  user-select: none;
  flex: 1;
  align-self: stretch;
}

.infoBox:not(:last-child) {
  margin-right: 15px;
}

.infoBox__cases {
  color: #cc1034;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.infoBox__total {
  color: #6c757d;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  margin-top: 15px !important;
}

.infoBox--selected {
  border-top: 10px solid greenyellow;
}

.infoBox--red {
  border-color: red;
}

.infoBox--green {
  color: lightgreen;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}


.circle-info-container {
  width: 150px;
}

.circle-info-container__flag {
  height: 80px;
  width: 100%;
  background-size: cover;
  border-radius: 8px;
}

.circle-info-container__flag img {
  width: 100px;
  border-radius: 5px;
}

.circle-info-container__name {
  font-size: 20px;
  font-weight: bold;
  color: #555;
}

.circle-info-container__cases,
.circle-info-container__recovered,
.circle-info-container__deaths {
  font-size: 14px;
  margin-top: 5px !important;
}